<template>
  <div class="pannel">
    <el-card>
      <div slot="header">
        <el-row type="flex" justify="space-between"
          ><el-col :span="20"><span>My Molecular Data</span></el-col
          ><el-col :span="1.5">
            <el-button
              type="success"
              round
              icon="el-icon-circle-plus-outline"
              @click="Add()"
              :loading="isLoading"
              >Add</el-button
            >
            <MyMoleculeInfoAdd
              @getInfo="getInfo"
              ref="MyMoleculeInfoAdd" /></el-col
        ></el-row>
      </div>
    </el-card>
    <el-divider></el-divider>
    <el-table :data="infoList" style="width: 100%" v-loading="loading">
      <el-table-column prop="name" label="Name"> </el-table-column>
      <el-table-column prop="molecular_formula" label="Formula">
      </el-table-column>
      <el-table-column prop="cas_number" label="CAS">
        <template slot-scope="scope">
          {{
            scope.row.cas_number == "" ? "-" : scope.row.cas_number
          }}
        </template>
      </el-table-column>
      <el-table-column prop="pub_chem_cid" label="CID">
        <template slot-scope="scope">
          {{
            scope.row.pub_chem_cid == "" ? "-" : scope.row.pub_chem_cid
          }}
        </template>
      </el-table-column>
      <el-table-column prop="create_time" label="Time created" width="180">
        <template slot-scope="scope">
          <el-tag type="warning">{{
            timestampToTime(scope.row.create_time)
          }}</el-tag>
        </template>
      </el-table-column>
      <el-table-column prop="update_time" label="Last updated" width="180">
        <template slot-scope="scope">
          <el-tag type="warning">{{
            timestampToTime(scope.row.update_time)
          }}</el-tag>
        </template>
      </el-table-column>
      <el-table-column prop="status" width="115" label="Status"
        ><template slot-scope="scope">
          <el-tag
            :type="
              scope.row.status == 1
                ? 'info'
                : scope.row.status == 2
                ? 'success'
                : scope.row.status == 3
                ? 'warning'
                : 'danger'
            "
            >{{
              scope.row.status == 1
                ? "ToBeReviewed"
                : scope.row.status == 2
                ? "Passed"
                : scope.row.status == 3
                ? "Unpassed"
                : "Offline"
            }}</el-tag
          >
        </template>
      </el-table-column>
      <el-table-column prop="create_user_name" label="Founder" width="80">
        <template slot-scope="scope">
          <el-tag type="danger" effect="plain">{{
            scope.row.create_user_name
          }}</el-tag>
        </template>
      </el-table-column>
      <el-table-column label="Operatiion" width="200">
        <template slot-scope="scope">
          <el-button size="mini" icon="el-icon-edit" @click="handleEdit(scope.$index, scope.row)"
            >Edit</el-button
          >
          <el-button
            size="mini"
            type="primary"
            icon="el-icon-view"
            @click="handleShowDetail(scope.$index, scope.row)"
            >View</el-button
          >
        </template>
      </el-table-column>
    </el-table>
    <el-pagination
      @size-change="handleSizeChange"
      @current-change="handleCurrentChange"
      :current-page="pageNum"
      :page-sizes="[5, 10, 15, 20]"
      :page-size="pageSize"
      layout="total, sizes, prev, pager, next, jumper"
      :total="total"
    >
    </el-pagination>
    <MyMoleculeInfoEdit :form="form" ref="MyMoleculeInfoEdit" @getInfo="getInfo" />
    <MyMoleculeInfoShowDetail ref="MyMoleculeInfoShowDetail" />
  </div>
</template>

<script>
import MyMoleculeInfoAdd from "../MyMoleculeInfoAdd/index.vue";
import MyMoleculeInfoEdit from "../MyMoleculeInfoEdit/index.vue"
import MyMoleculeInfoShowDetail from '../MyMoleculeInfoShowDetail/index.vue'
export default {
  components: { MyMoleculeInfoAdd,MyMoleculeInfoEdit,MyMoleculeInfoShowDetail },
  data() {
    return {
      isLoading: false,
      loading: false,
      //分页数据--start
      pageSize: 10,
      pageNum: 1,
      total: 0,
      //分页数据--end
      infoList: [],
      form: {},
    };
  },
  mounted() {
    this.getInfo();
  },
  methods: {
    //获取分子信息
    getInfo() {
      this.loading = true;
      this.$store
        .dispatch("myMoleculeInfo/select", {
          pagesize: this.pageSize,
          pagenum: this.pageNum,
        })
        .then((res) => {
          const { data } = res;
          this.total = data.total;
          this.infoList = data.info_list;
          this.loading = false;
        })
        .catch((err) => {
          console.log(err);
          this.loading = true;
        });
    },
    //分页控件函数--start
    handleSizeChange(val) {
      this.pageSize = val;
      this.getInfo();
    },
    handleCurrentChange(val) {
      this.pageNum = val;
      this.getInfo();
    },
    //分页控件函数--end
    //根据条件模糊查询分子条目
    // handleSearch() {
    //   this.getInfo();
    // },
    //查看分子条目详情
    handleShowDetail(index, row) {
      this.$refs.MyMoleculeInfoShowDetail.handleOpen(row)
    },
    //时间戳转换为时间
    timestampToTime(timestamp) {
      var date = new Date(parseInt(timestamp)); //时间戳为10位需*1000，时间戳为13位的话不需乘1000,需要将字符串转化为int
      var Y = date.getFullYear() + "-";
      var M =
        (date.getMonth() + 1 < 10
          ? "0" + (date.getMonth() + 1)
          : date.getMonth() + 1) + "-";
      var D =
        (date.getDate() < 10 ? "0" + date.getDate() : date.getDate()) + " ";
      var h =
        (date.getHours() < 10 ? "0" + date.getHours() : date.getHours()) + ":";
      var m =
        (date.getMinutes() < 10 ? "0" + date.getMinutes() : date.getMinutes()) +
        ":";
      var s =
        date.getSeconds() < 10 ? "0" + date.getSeconds() : date.getSeconds();
      return Y + M + D + h + m + s;
    },
    //添加分子条目信息按钮函数
    Add() {
      this.$refs.MyMoleculeInfoAdd.handleOpen();
    },
    //编辑列函数
    handleEdit(index, row) {
      this.form = row;
      this.$refs.MyMoleculeInfoEdit.handleOpen(this.form);
    },
  },
};
</script>

<style scoped>
.pannel {
  background-color: #fff;
}
::v-deep .el-card__header {
  padding: 10px;
  color: #666;
}
</style>