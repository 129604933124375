<template>
  <el-dialog
    title="Edit Molecular Data"
    :visible="dialogEditFormVisible"
    width="80rem"
    :close-on-click-modal="false"
    :show-close="false"
  >
    <el-divider>Basic information about the molecule</el-divider>
    <el-form :model="form" :rules="rules" ref="form">
      <el-row type="flex"
        ><el-col
          ><el-form-item label="Name" :label-width="formLabelWidth" prop="name">
            <el-input
              v-model="form.name"
              placeholder="Enter a molecule name"
            ></el-input> </el-form-item></el-col
        ><el-col
          ><el-form-item
            label="Formula"
            :label-width="formLabelWidth"
            prop="molecular_formula"
          >
            <el-input
              v-model="form.molecular_formula"
              placeholder="Enter a molecular formula"
            ></el-input> </el-form-item></el-col
      ></el-row>
      <el-row type="flex"
        ><el-col
          ><el-form-item
            label="CAS number"
            :label-width="formLabelWidth"
            prop="cas_number"
          >
            <el-input
              v-model="form.cas_number"
              placeholder="Enter a CAS number"
            ></el-input> </el-form-item></el-col
        ><el-col
          ><el-form-item
            label="CID number"
            :label-width="formLabelWidth"
            prop="pub_chem_cid"
          >
            <el-input
              v-model="form.pub_chem_cid"
              placeholder="Enter a CID number"
            ></el-input> </el-form-item></el-col
      ></el-row>
    </el-form>

    <el-card class="propertie_card">
      <div slot="header">
        <el-row type="flex" justify="space-between"
          ><el-col :span="19"><b>Molecular properties</b></el-col
          ><el-col :span="4.5"
            ><el-button
              type="primary"
              icon="el-icon-edit"
              round
              v-if="!isEditPropertie"
              @click="editPropertie"
              >Edit</el-button
            ><el-button
              type="danger"
              icon="el-icon-close"
              round
              v-if="isEditPropertie"
              @click="cancelEditPropertie"
              >Cancel</el-button
            >
            <el-button
              type="success"
              icon="el-icon-circle-check"
              round
              v-if="isEditPropertie"
              @click="savePropertie"
              >Save</el-button
            >
          </el-col></el-row
        >
      </div>
      <div
        v-html="content"
        class="propertie_pannel"
        v-if="!isEditPropertie"
        title="To edit the properties, click the “Edit Button”"
      ></div>
      <WangEditor
        ref="WangEditor"
        @refreshContent="refreshContent"
        v-show="isEditPropertie"
    /></el-card>

    <el-row type="flex" :gutter="10"
      ><el-col>
        <el-card
          ><div slot="header"><b>Molecule-related documents</b></div>
          <el-row type="flex" class="file_pannel"
            ><el-col :span="12"
              ><span>Molecular structure<br />(Only Gaussian .gjf is allowed)</span>
              <el-upload
                :action="uploadFileApiUrl"
                :headers="headerObj"
                :data="postData"
                :on-preview="handlePreview"
                :on-remove="handleRemove"
                :on-success="handleSuccess"
                :on-error="handleError"
                :on-exceed="handleExceed"
                :before-remove="beforeRemove"
                ref="upload"
                :limit="1"
                :file-list="structFileList"
              >
                <el-button
                  size="small"
                  type="primary"
                  @click="handleUpload('struct_file')"
                  >Upload</el-button
                >
                <div
                  slot="tip"
                  class="el-upload__tip"
                  v-if="structFileList.length == 0"
                >
                  No data
                </div>
                <div
                  slot="tip"
                  class="el-upload__tip upload-tip"
                  v-if="structFileList.length == 0"
                >
                  Only .gjf are allowed
                </div>
              </el-upload></el-col
            ><el-col :span="12"
              ><span>Molecular geometry optimization result <br />(Only Gaussian .fch is allowed)</span
              ><el-upload
                :action="uploadFileApiUrl"
                :headers="headerObj"
                :data="postData"
                :on-preview="handlePreview"
                :on-remove="handleRemove"
                :on-success="handleSuccess"
                :on-error="handleError"
                :on-exceed="handleExceed"
                :before-remove="beforeRemove"
                ref="upload"
                :limit="1"
                :file-list="calculationResultFileList"
              >
                <el-button
                  size="small"
                  type="primary"
                  @click="handleUpload('calculation_result_file')"
                  >Upload</el-button
                >
                <div
                  slot="tip"
                  class="el-upload__tip"
                  v-if="calculationResultFileList.length == 0"
                >
                  No data
                </div>
                <div
                  slot="tip"
                  class="el-upload__tip upload-tip"
                  v-if="calculationResultFileList.length == 0"
                >
                  Only .fch are allowed
                </div>
              </el-upload></el-col
            ></el-row
          ></el-card
        ></el-col
      ><el-col>
        <el-card
          ><div slot="header">
            <el-row type="flex" justify="space-between" :gutter="10">
              <el-col :span="5"><b>References</b></el-col>
              <el-col :span="19"
                ><el-input
                  v-model="referenceContent"
                  placeholder="Please enter the references"
                ></el-input></el-col
              ><el-col :span="4"
                ><el-button
                  type="success"
                  round
                  @click="addReference"
                  icon="el-icon-plus"
                  >Add</el-button
                ></el-col
              >
            </el-row>
          </div>
          <ul v-if="references.length != 0" class="references_pannel">
            <li v-for="(item, index) in references" :key="index">
              <el-card shadow="hover" class="li_card">
                <el-row type="flex" justify="space-between"
                  ><el-col :span="20"
                    ><span>{{ item.content }}</span></el-col
                  ><el-col :span="2"
                    ><el-button
                      type="danger"
                      icon="el-icon-delete"
                      size="mini"
                      circle
                      @click="del(item)"
                    ></el-button></el-col
                ></el-row>
              </el-card>
            </li>
          </ul>
          <span v-else>No information</span>
        </el-card>
      </el-col></el-row
    >
    <div slot="footer" class="dialog-footer">
      <el-button @click="handleEditCancel()" icon="el-icon-circle-close"
        >Cancel</el-button
      >
      <el-button
        type="primary"
        :loading="isLoading"
        @click="handleSave()"
        icon="el-icon-circle-check"
        :disabled="isEditPropertie"
        :title="
          isEditPropertie == false
            ? ''
            : 'Molecular properties are currently being edited and cannot be saved'
        "
        >Save</el-button
      >
    </div>
  </el-dialog>
</template>
  
  <script>
import axios from "axios";
import WangEditor from "../../../components/WangEditor/index.vue";
export default {
  name: "MyMoleculeInfoEdit",
  components: { WangEditor },
  data() {
    return {
      //上传文件接口url
      uploadFileApiUrl: process.env.VUE_APP_API_URL + "/file/upload",
      //下载文件进度条所需参数
      percentage: 0, //进度条的占比
      isShowPercentage: false, //是否显示进度条
      dialogEditFormVisible: false,
      isLoading: false,
      isEditPropertie: false, //是否对分子性质进行编辑标志
      formLabelWidth: "8rem",
      headerObj: {
        Authorization: " Bearer " + this.$store.state.tokenObj.token,
      },
      //原来的分子性质内容
      content: "",
      //新的分子性质内容
      newContent: "",
      postData: {
        file_type: "",
        molecule_info_id: -1,
      },
      //分子结构文件相关参数
      structFileList: [],
      //分子计算结果文件相关参数
      calculationResultFileList: [],
      //参考文献内容
      referenceContent: "",
      //参考文献列表
      references: [],
      form: {},
      //表单验证规则
      rules: {},
    };
  },
  methods: {
    selectFile() {
      this.$store
        .dispatch("moleculeInfo/selectFile", {
          id: this.form.id,
        })
        .then((res) => {
          const { data } = res;
          if (data.struct_file) {
            this.structFileList.push(data.struct_file);
          }
          if (data.calculation_result_file) {
            this.calculationResultFileList.push(data.calculation_result_file);
          }
        });
    },
    //获取分子性质记录
    getPropertieRecord() {
      this.$store
        .dispatch("propertieRecord/select", {
          molecule_info_id: this.form.id,
        })
        .then((res) => {
          if (res.data != undefined && res.data != null) {
            this.content = res.data.content;
          } else {
            this.content = "";
          }
        });
    },
    //获取参考文献列表
    getReferences() {
      this.$store
        .dispatch("references/select", {
          molecule_info_id: this.form.id,
        })
        .then((res) => {
          var { data } = res;
          this.references = [];
          data.forEach((item) => {
            this.references.push(item);
          });
        })
        .catch((err) => {
          console.log(err);
        });
    },
    //添加分子条目参考文献
    addReference() {
      this.$store
        .dispatch("references/add", {
          molecule_info_id: this.form.id,
          content: this.referenceContent,
        })
        .then((res) => {
          this.$message.success(res.msg);
          this.getReferences();
        })
        .catch((err) => {
          console.log(err);
        });
      this.referenceContent = "";
    },
    //删除参考文献
    del(item) {
      this.$store
        .dispatch("references/del", {
          molecule_info_id: this.form.id,
          id: item.id,
        })
        .then((res) => {
          this.$message.success(res.msg);
          this.getReferences();
        })
        .catch((err) => {
          console.log(err);
        });
    },
    //编辑更新内容回调函数
    refreshContent(content) {
      this.newContent = content;
    },
    //编辑分子性质
    editPropertie() {
      this.isEditPropertie = true;
      this.$refs.WangEditor.initHtml(this.content);
    },
    //取消编辑分子性质
    cancelEditPropertie() {
      this.isEditPropertie = false;
    },
    //保存分子性质
    savePropertie() {
      this.$store
        .dispatch("propertieRecord/add", {
          molecule_info_id: this.form.id,
          content: this.newContent,
        })
        .then((res) => {
          this.$notify({
            title: "Edited successfully",
            message:
              'Your edits to the molecular properties have been completed, and they will be displayed in the molecular entry after the relevant personnel have completed the review, and you can view the review progress in the "Molecular Property Editing Record".',
            type: "success",
            duration: 5000,
            showClose: false,
          });
          this.isEditPropertie = false;
          this.newContent = "";
        })
        .catch((err) => {
          this.$message.error("Something is wrong!");
          console.log(err);
        });
      this.handleEditCancel();
    },
    handleOpen(data) {
      //初始化下载进度条参数
      this.percentage = 0;
      this.dialogEditFormVisible = true;
      this.form = data;
      this.selectFile();
      this.getPropertieRecord();
      this.getReferences();
    },
    //保存修改的信息
    handleSave() {
      this.$store
        .dispatch("moleculeInfo/edit", {
          id: this.form.id,
          name: this.form.name,
          molecular_formula: this.form.molecular_formula,
          cas_number: this.form.cas_number,
          pub_chem_cid: this.form.pub_chem_cid,
        })
        .then((res) => {
          this.$message.success("Save successfully!");
          this.handleEditCancel();
        })
        .catch(() => {
          this.$message.error("Save failed!");
        });
    },
    handleEditCancel() {
      this.dialogEditFormVisible = false;
      this.isEditPropertie = false;
      this.structFileList = [];
      this.calculationResultFileList = [];
      this.references = [];
      this.isShowPercentage = false;
      this.$emit("getInfo");
    },
    handleUpload(fileType) {
      this.postData.file_type = fileType;
      this.postData.molecule_info_id = this.form.id;
    },
    //上传文件相关操作函数--start
    handleRemove(file, fileList) {
      this.$store
        .dispatch("moleculeInfo/delFile", {
          id: this.form.id,
          file_type: file.type,
          file_id: file.id,
        })
        .then((res) => {
          this.$message.success("Deleted successfully!");
        })
        .catch(() => {
          this.$message.error("Delete failed!");
        });
      if (file.type == "struct_file") {
        this.structFileList = [];
      } else {
        this.calculationResultFileList = [];
      }
    },
    handleSuccess(response, file, fileList) {
      this.$message.success("Upload successful!");
      if (response.type == "struct_file") {
        this.structFileList.push(response);
      } else {
        this.calculationResultFileList.push(response);
      }
    },
    handleError(err, file, fileList) {
      this.$message.error("File upload failed!");
      console.log(err);
    },
    handlePreview(file) {
      //通过访问minio的文件链接获取文件
      //不添加token的请求方式--start
      // const getFileUrlInstance = axios.create({
      //   baseURL: process.env.VUE_APP_API_URL + "/file/download/",
      // });
      //不添加token的请求方式--end
      axios({
        method: "get",
        url: process.env.VUE_APP_API_URL + "/file/download/" + file.url,
      }).then((res) => {
        // 获取文件的访问链接
        const { file_url } = res.data;
        this.$message.success("Downloading file......");
        this.isShowPercentage = true;
        //初始化下载进度条参数
        this.percentage = 0;
        const getFileInstance = axios.create({
          baseURL: file_url,
        });
        getFileInstance({
          timeout: 0, // 不设置超时时间
          method: "get",
          responseType: "blob",
          onDownloadProgress: (progress) => {
            this.percentage = progress.progress * 100;
          },
        })
          .then((res) => {
            this.downloadFile(res.data, file.name);
          })
          .catch((error) => {
            this.$message.error(
              "File download failed! Please contact your administrator"
            );
            this.isShowPercentage = false;
            console.log(error);
          });
      });
    },
    downloadFile: function (data, fileName) {
      this.$message.success("Download complete! Compiling files......");
      // data为blob格式
      var blob = new Blob([data]);
      var downloadElement = document.createElement("a");
      var href = window.URL.createObjectURL(blob);
      downloadElement.href = href;
      downloadElement.download = fileName;
      document.body.appendChild(downloadElement);
      downloadElement.click();
      document.body.removeChild(downloadElement);
      window.URL.revokeObjectURL(href);
    },

    beforeRemove(file, fileList) {
      return this.$confirm(
        `Are you sure you want to remove the file: ${file.name}？`
      );
    },
    handleExceed(files, fileList) {
      this.$message.warning(
        `There is currently a limit of 1 file to be uploaded, if you want to upload again, please delete the existing file!`
      );
      this.$refs.upload.abort(); //终止上传
    },
    //上传文件相关操作函数--end
  },
};
</script>
  
  <style scoped>
.propertie_pannel {
  height: 15rem;
  overflow-y: overlay !important;
  overflow-x: hidden !important;
}
ul {
  margin: 0;
  padding: 0;
}
li {
  list-style-type: none;
}
.li_card ::v-deep .el-card__body {
  padding: 10px !important;
}
.propertie_card::v-deep .el-card__body {
  padding: 10px !important;
}
.file_pannel {
  height: 7.5rem;
  overflow-y: overlay !important;
  overflow-x: hidden !important;
}
.references_pannel {
  height: 6rem;
  overflow-y: overlay !important;
  overflow-x: hidden !important;
}
</style>