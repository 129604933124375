<template>
  <el-dialog
    title="View the details of the molecule"
    :visible="dialogoDetailVisible"
    width="70rem"
    :close-on-click-modal="false"
    :show-close="false"
  >
    <el-descriptions title="Basic information about the molecule" :column="2" border>
      <el-descriptions-item label="Name">{{
        form.name
      }}</el-descriptions-item>
      <el-descriptions-item label="Formula">{{
        form.molecular_formula
      }}</el-descriptions-item>
      <el-descriptions-item label="CAS"
        >{{
          form.cas_number == "" ? "-" : form.cas_number
        }}</el-descriptions-item
      >
      <el-descriptions-item label="CID"
        >{{
          form.pub_chem_cid == "" ? "-" : form.pub_chem_cid
        }}</el-descriptions-item
      >
      <el-descriptions-item label="Time created">
        <el-tag type="warning">{{
          timestampToTime(form.create_time)
        }}</el-tag></el-descriptions-item
      >
      <el-descriptions-item label="Last updated"
        ><el-tag type="warning">{{
          form.update_time
        }}</el-tag></el-descriptions-item
      >
      <el-descriptions-item label="Founder"
        ><el-tag type="danger">{{
          form.create_user_name
        }}</el-tag></el-descriptions-item
      >
      <el-descriptions-item label="Status">
        <el-tag
          :type="
            form.status == 1
              ? 'info'
              : form.status == 2
              ? 'success'
              : form.status == 3
              ? 'warning'
              : 'danger'
          "
          >{{
            form.status == 1
              ? "ToBeReviewed"
              : form.status == 2
              ? "Passed"
              : form.status == 3
              ? "Unpassed"
              : "Offline"
          }}</el-tag
        >
      </el-descriptions-item>
    </el-descriptions>

    <el-card class="propertie_card">
      <div slot="header">
        <el-row type="flex"><el-col>Molecular properties</el-col></el-row>
      </div>
      <div class="propertie_pannel" v-html="contentobj.content"></div>
    </el-card>
    <el-row type="flex" :gutter="10"
      ><el-col>
        <el-card
          ><div slot="header"><b>Molecule-related documents</b></div>
          <el-row type="flex" class="file_pannel"
            ><el-col :span="12"
              ><span>Molecular structure<br />(Only Gaussian .gjf is allowed)</span>
              <el-upload
                action=""
                disabled
                :on-preview="handlePreview"
                ref="upload"
                :limit="1"
                :file-list="structFileList"
              >
                <div
                  slot="tip"
                  class="el-upload__tip"
                  v-if="structFileList.length == 0"
                >
                  No data
                </div>
              </el-upload></el-col
            ><el-col :span="12"
              ><span>Molecular geometry optimization result <br />(Only Gaussian .fch is allowed)</span
              ><el-upload
                action=""
                disabled
                :on-preview="handlePreview"
                ref="upload"
                :limit="1"
                :file-list="calculationResultFileList"
              >
                <div
                  slot="tip"
                  class="el-upload__tip"
                  v-if="calculationResultFileList.length == 0"
                >
                  No data
                </div>
              </el-upload></el-col
            ></el-row
          ></el-card
        ><el-progress
          :percentage="percentage"
          v-if="isShowPercentage"
        ></el-progress></el-col
      ><el-col>
        <el-card
          ><div slot="header">
            <el-row type="flex" justify="space-between" :gutter="10">
              <el-col :span="4"><b>References</b></el-col>
              <el-col :span="16"></el-col>
            </el-row>
          </div>
          <ul v-if="references.length != 0" class="references_pannel">
            <li v-for="(item, index) in references" :key="index">
              <el-card shadow="hover" class="li_card">
                <el-row type="flex" justify="space-between"
                  ><el-col :span="24"
                    ><span>{{ item.content }}</span></el-col
                  ></el-row
                >
              </el-card>
            </li>
          </ul>
          <span v-else>No information</span>
        </el-card>
      </el-col></el-row
    >
    <div slot="footer" class="dialog-footer">
      <el-button
        type="primary"
        plain
        @click="handleCancel()"
        icon="el-icon-circle-close"
        >Close</el-button
      >
    </div>
  </el-dialog>
</template>

<script>
import axios from "axios";
export default {
  name: "MyMoleculeInfoShowDetail",
  data() {
    return {
      //下载文件进度条所需参数
      percentage: 0, //进度条的占比
      isShowPercentage: false, //是否显示进度条
      form: {},
      dialogoDetailVisible: false,
      //分子性质数据
      contentobj: "",
      //分子结构文件相关参数
      structFileList: [],
      //分子计算结果文件相关参数
      calculationResultFileList: [],
      //参考文献列表
      references: [],
    };
  },
  mounted() {},
  methods: {
    handleOpen(data) {
      //初始化下载进度条参数
      this.percentage = 0;
      this.form = data;
      this.dialogoDetailVisible = true;
      this.getPropertieRecord();
      this.selectFile();
      this.getReferences();
    },
    handleCancel() {
      this.form = {};
      this.contentobj = "";
      this.structFileList = [];
      this.calculationResultFileList = [];
      this.references = [];
      this.dialogoDetailVisible = false;
      this.isShowPercentage = false;
    },
    //获取分子最新性质
    getPropertieRecord() {
      this.$store
        .dispatch("propertieRecord/select", {
          molecule_info_id: this.form.id,
        })
        .then((res) => {
          const { data } = res;
          if (data == null) {
            this.$message.warning("No molecular property data are available");
            this.contentobj = "";
          } else {
            this.contentobj = data;
          }
        });
    },
    //跳转至编辑分子性质页面
    handleEdit() {
      this.$confirm(
        "You will be redirected to the Edit Molecular Properties page, if you are not logged in, you will be redirected to the login page to log in, Do you want to continue?",
        "tips",
        {
          confirmButtonText: "Yes",
          cancelButtonText: "Cancel",
          type: "warning",
        }
      )
        .then(() => {
          this.$store.dispatch("user/me").then((res) => {
            this.$router.push({
              name: "MoleculeInfoEdit",
              params: {
                data: this.form,
              },
            });
          });
        })
        .catch(() => {
          this.$message({
            type: "info",
            message: "Cancel edit",
          });
        });
    },
    //获取分子条目相关文件
    selectFile() {
      this.$store
        .dispatch("moleculeInfo/selectFile", {
          id: this.form.id,
        })
        .then((res) => {
          const { data } = res;
          if (data.struct_file) {
            this.structFileList.push(data.struct_file);
          }
          if (data.calculation_result_file) {
            this.calculationResultFileList.push(data.calculation_result_file);
          }
        });
    },
    //获取参考文献列表
    getReferences() {
      this.$store
        .dispatch("references/select", {
          molecule_info_id: this.form.id,
        })
        .then((res) => {
          var { data } = res;
          this.references = [];
          data.forEach((item) => {
            this.references.push(item);
          });
        })
        .catch((err) => {
          console.log(err);
        });
    },
    //下载预览文件
    handlePreview(file) {
      //通过访问minio的文件链接获取文件
      //不添加token的请求方式--start
      // const getFileUrlInstance = axios.create({
      //   baseURL: process.env.VUE_APP_API_URL + "/file/download/",
      // });
      //不添加token的请求方式--end
      axios({
        method: "get",
        url: process.env.VUE_APP_API_URL + "/file/download/" + file.url,
      }).then((res) => {
        // 获取文件的访问链接
        const { file_url } = res.data;
        this.$message.success("Downloading file......");
        this.isShowPercentage = true;
        //初始化下载进度条参数
        this.percentage = 0;
        const getFileInstance = axios.create({
          baseURL: file_url,
        });
        getFileInstance({
          timeout: 0, // 不设置超时时间
          method: "get",
          responseType: "blob",
          onDownloadProgress: (progress) => {
            this.percentage = progress.progress * 100;
          },
        })
          .then((res) => {
            this.downloadFile(res.data, file.name);
          })
          .catch((error) => {
            this.$message.error("File download failed! Please contact your administrator");
            this.isShowPercentage = false;
            console.log(error);
          });
      });
    },
    downloadFile: function (data, fileName) {
      this.$message.success("Download complete! Compiling files......");
      // data为blob格式
      var blob = new Blob([data]);
      var downloadElement = document.createElement("a");
      var href = window.URL.createObjectURL(blob);
      downloadElement.href = href;
      downloadElement.download = fileName;
      document.body.appendChild(downloadElement);
      downloadElement.click();
      document.body.removeChild(downloadElement);
      window.URL.revokeObjectURL(href);
    },
    //时间戳转换为时间
    timestampToTime(timestamp) {
      var date = new Date(parseInt(timestamp)); //时间戳为10位需*1000，时间戳为13位的话不需乘1000,需要将字符串转化为int
      var Y = date.getFullYear() + "-";
      var M =
        (date.getMonth() + 1 < 10
          ? "0" + (date.getMonth() + 1)
          : date.getMonth() + 1) + "-";
      var D =
        (date.getDate() < 10 ? "0" + date.getDate() : date.getDate()) + " ";
      var h =
        (date.getHours() < 10 ? "0" + date.getHours() : date.getHours()) + ":";
      var m =
        (date.getMinutes() < 10 ? "0" + date.getMinutes() : date.getMinutes()) +
        ":";
      var s =
        date.getSeconds() < 10 ? "0" + date.getSeconds() : date.getSeconds();
      return Y + M + D + h + m + s;
    },
  },
};
</script>

<style scoped>
.propertie_pannel {
  height: 15rem;
  overflow-y: overlay !important;
  overflow-x: hidden !important;
}
.propertie_card::v-deep .el-card__body {
  padding: 10px !important;
}
.file_pannel {
  height: 7.5rem;
  overflow-y: overlay !important;
  overflow-x: hidden !important;
}
.references_pannel {
  height: 7.5rem;
  overflow-y: overlay !important;
  overflow-x: hidden !important;
}
</style>